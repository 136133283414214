var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "screen",
      class: {
        secondary: _vm.completed_quiz,
        "completed-quiz": _vm.completed_quiz
      },
      attrs: { id: "quiz" }
    },
    [
      _c(
        "a",
        { staticClass: "logo respond", attrs: { href: _vm.$app.site_url } },
        [_c("img", { attrs: { src: _vm.$app.logo.white, alt: "logo" } })]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.completed_quiz,
              expression: "!completed_quiz"
            }
          ],
          attrs: { id: "quiz-questions" }
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "step step-1" }, [
            _c("p", { staticClass: "q-label" }, [_vm._v("How old are you?")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "options" },
              _vm._l(_vm.list.age, function(age, index) {
                return _c("div", { staticClass: "radio" }, [
                  _c(
                    "label",
                    {
                      staticClass: "ui-btn ui-btn-rounded",
                      on: {
                        click: function($event) {
                          return _vm.setValueAndProceed(
                            "age",
                            age.label,
                            age.info
                          )
                        }
                      }
                    },
                    [
                      _c("input", {
                        attrs: { type: "radio", required: "" },
                        domProps: { value: age.label }
                      }),
                      _vm._v(" "),
                      _c("span", {
                        domProps: { textContent: _vm._s(age.label) }
                      })
                    ]
                  )
                ])
              }),
              0
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "step step-2" }, [
            _c("p", { staticClass: "q-label" }, [_vm._v("My smile has...")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-row options column-style" },
              _vm._l(_vm.list.smile_options, function(option, index) {
                return _c("div", { staticClass: "col l6 m6 s6" }, [
                  _c("div", { staticClass: "radio" }, [
                    _c(
                      "label",
                      {
                        staticClass: "ui-btn ui-btn-rounded",
                        on: {
                          click: function($event) {
                            return _vm.setValueAndProceed(
                              "my_smile_has",
                              option.label,
                              option.info
                            )
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src:
                              _vm.$app.assets_url +
                              "/images/icons/" +
                              option.icon +
                              ".png",
                            alt: option.label
                          }
                        }),
                        _vm._v(" "),
                        _c("input", {
                          attrs: { type: "radio", required: "" },
                          domProps: { value: option.label }
                        }),
                        _vm._v(" "),
                        _c("span", {
                          domProps: { textContent: _vm._s(option.label) }
                        })
                      ]
                    )
                  ])
                ])
              }),
              0
            )
          ]),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "step step-4" }, [
            _c("p", { staticClass: "q-label" }, [_vm._v("Do you have any...")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "options" },
              _vm._l(_vm.list.do_you_have_any, function(option, index) {
                return _c("div", { staticClass: "radio" }, [
                  _c(
                    "label",
                    {
                      staticClass: "ui-btn ui-btn-rounded",
                      on: {
                        click: function($event) {
                          return _vm.setValueAndProceed(
                            "do_you_have_any",
                            option.label,
                            option.info
                          )
                        }
                      }
                    },
                    [
                      _c("input", {
                        attrs: { type: "radio", required: "" },
                        domProps: { value: option.label }
                      }),
                      _vm._v(" "),
                      _c("span", {
                        domProps: { textContent: _vm._s(option.label) }
                      })
                    ]
                  )
                ])
              }),
              0
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "invisalign-logo respondmaxi" }, [
            _c("img", {
              attrs: {
                src: _vm.$app.assets_url + "/images/logo/logo-invisalign.png",
                alt: "logo-invisalign"
              }
            })
          ])
        ]
      ),
      _vm._v(" "),
      _vm.completed_quiz
        ? _c(
            "form",
            {
              attrs: {
                id: "quiz-form",
                method: "post",
                action: "https://app.dengro.com/register-interest",
                enctype: "multipart/form-data"
              },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.saveLead.apply(null, arguments)
                }
              }
            },
            [
              !_vm.more_info_needed
                ? _c("div", { staticClass: "copy" }, [
                    _c("p", { staticClass: "heading" }, [
                      _vm._v("Congratulations!")
                    ]),
                    _vm._v(" "),
                    _vm._m(2)
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.more_info_needed
                ? _c("div", { staticClass: "copy" }, [
                    _c("p", { staticClass: "heading" }, [
                      _vm._v("We'd like to assess your smile.")
                    ]),
                    _vm._v(" "),
                    _vm._m(3)
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _vm._m(4),
              _vm._v(" "),
              _c("input", {
                attrs: {
                  type: "hidden",
                  name: "token",
                  value: "a651553e-b533-3f4a-97b2-46eaadf1f377"
                }
              }),
              _vm._v(" "),
              _c("input", {
                attrs: {
                  type: "hidden",
                  name: "treatment",
                  value: "invisalign"
                }
              }),
              _vm._v(" "),
              _c("input", {
                attrs: { type: "hidden", name: "success_url" },
                domProps: { value: _vm.$app.site_url + "/thanks" }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "form-block" }, [
                _c(
                  "label",
                  {
                    staticClass: "form-label required",
                    attrs: { id: "your-firstname" }
                  },
                  [
                    _c("div", { staticClass: "form-field input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.first_name,
                            expression: "model.first_name"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "first_name",
                          placeholder: "First name",
                          required: ""
                        },
                        domProps: { value: _vm.model.first_name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model,
                              "first_name",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-block" }, [
                _c(
                  "label",
                  {
                    staticClass: "form-label required",
                    attrs: { id: "your-lastname" }
                  },
                  [
                    _c("div", { staticClass: "form-field input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.last_name,
                            expression: "model.last_name"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "last_name",
                          placeholder: "Last name",
                          required: ""
                        },
                        domProps: { value: _vm.model.last_name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model,
                              "last_name",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-block" }, [
                _c(
                  "label",
                  {
                    staticClass: "form-label required",
                    attrs: { id: "your-email" }
                  },
                  [
                    _c("div", { staticClass: "form-field input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.email,
                            expression: "model.email"
                          }
                        ],
                        attrs: {
                          type: "email",
                          name: "email",
                          pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$",
                          placeholder: "Email address",
                          required: ""
                        },
                        domProps: { value: _vm.model.email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.model, "email", $event.target.value)
                          }
                        }
                      })
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-block" }, [
                _c(
                  "label",
                  {
                    staticClass: "form-label required",
                    attrs: { id: "your-phone-number" }
                  },
                  [
                    _c("div", { staticClass: "form-field input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.phone_number,
                            expression: "model.phone_number"
                          }
                        ],
                        attrs: {
                          type: "tel",
                          name: "mobile",
                          pattern: "[0-9]*",
                          maxlength: "20",
                          placeholder: "Phone number",
                          required: ""
                        },
                        domProps: { value: _vm.model.phone_number },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model,
                              "phone_number",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-block" }, [
                _c(
                  "label",
                  {
                    staticClass: "form-label required",
                    attrs: { id: "your-postcode" }
                  },
                  [
                    _c("div", { staticClass: "form-field input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.postcode,
                            expression: "model.postcode"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "postcode",
                          maxlength: "8",
                          placeholder: "Postcode",
                          required: ""
                        },
                        domProps: { value: _vm.model.postcode },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.model, "postcode", $event.target.value)
                          }
                        }
                      })
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.age,
                    expression: "model.age"
                  }
                ],
                attrs: { type: "hidden", name: "meta_data[age]", required: "" },
                domProps: { value: _vm.model.age },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model, "age", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.my_smile_has,
                    expression: "model.my_smile_has"
                  }
                ],
                attrs: {
                  type: "hidden",
                  name: "meta_data[my_smile_has]",
                  required: ""
                },
                domProps: { value: _vm.model.my_smile_has },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model, "my_smile_has", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.smile_rate,
                    expression: "model.smile_rate"
                  }
                ],
                attrs: {
                  type: "hidden",
                  name: "meta_data[smile_rate]",
                  required: ""
                },
                domProps: { value: _vm.model.smile_rate },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model, "smile_rate", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.do_you_have_any,
                    expression: "model.do_you_have_any"
                  }
                ],
                attrs: {
                  type: "hidden",
                  name: "meta_data[do_you_have_any]",
                  required: ""
                },
                domProps: { value: _vm.model.do_you_have_any },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model, "do_you_have_any", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm._l(_vm.getUtmParams, function(param, i) {
                return _c("input", {
                  attrs: {
                    type: "hidden",
                    name: "meta_data[" + param.key + "]"
                  },
                  domProps: { value: param.value }
                })
              }),
              _vm._v(" "),
              _c("div", { staticClass: "form-block" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-label required",
                    attrs: { id: "your-consent" }
                  },
                  [
                    _c("div", { staticClass: "ui-checkbox" }, [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.consent_given,
                              expression: "model.consent_given"
                            }
                          ],
                          attrs: {
                            type: "checkbox",
                            name: "consent_given",
                            required: ""
                          },
                          domProps: {
                            checked: Array.isArray(_vm.model.consent_given)
                              ? _vm._i(_vm.model.consent_given, null) > -1
                              : _vm.model.consent_given
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.model.consent_given,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.model,
                                      "consent_given",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.model,
                                      "consent_given",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.model, "consent_given", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "checkbox" }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "I agree to Smmmile using my personal data to provide me with dental treatment."
                          )
                        ])
                      ])
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-block" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-label required",
                    attrs: { id: "your-marketing-consent" }
                  },
                  [
                    _c("div", { staticClass: "ui-checkbox" }, [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.marketing_consent_given,
                              expression: "model.marketing_consent_given"
                            }
                          ],
                          attrs: {
                            type: "checkbox",
                            name: "marketing_consent_given"
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.model.marketing_consent_given
                            )
                              ? _vm._i(
                                  _vm.model.marketing_consent_given,
                                  null
                                ) > -1
                              : _vm.model.marketing_consent_given
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.model.marketing_consent_given,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.model,
                                      "marketing_consent_given",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.model,
                                      "marketing_consent_given",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.model,
                                  "marketing_consent_given",
                                  $$c
                                )
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "checkbox" }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "I agree to Smmmile using my personal data to keep me informed about marketing offers and initiatives that may be of interest."
                          )
                        ])
                      ])
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "submit ui-btn",
                  attrs: {
                    type: "submit",
                    disabled: _vm.isSavingLead || !_vm.model.consent_given
                  },
                  domProps: {
                    textContent: _vm._s(
                      _vm.isSavingLead ? "Processing..." : "Submit"
                    )
                  }
                },
                [_vm._v("Submit")]
              )
            ],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "progress clearfix" }, [
      _c("div", { staticClass: "bar-completion" }, [
        _c("span", { staticClass: "percentage" }, [_vm._v("0%")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "step step-3" }, [
      _c("p", { staticClass: "q-label" }, [
        _vm._v("How would you rate your current smile?")
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "slider-rate" } }, [
        _c("div", { staticClass: "slider-labels" }),
        _vm._v(" "),
        _c("div", { attrs: { id: "slider" } }),
        _vm._v(" "),
        _c("div", { staticClass: "slider-labels-btm" }, [
          _c("span", [_vm._v("Almost "), _c("br"), _vm._v(" perfect!")]),
          _vm._v(" "),
          _c("span", [_vm._v("Very "), _c("br"), _vm._v(" misaligned")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("It looks like you're a candidate for Invisalign treatment. "),
      _c("br"),
      _vm._v(" "),
      _c("strong", [_vm._v("Book your FREE consultation now!")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "You may still be a candidate for Invisalign treatment, but we'd like to take a look at your smile to be sure. "
      ),
      _c("br"),
      _vm._v(" "),
      _c("strong", [_vm._v("Book your FREE consultation now!")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "copy" }, [
      _c("p", [
        _vm._v(
          "Pop your details below, and our in-store team will be in touch to show you how amazing your Smmmile can be!"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }