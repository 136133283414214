import '@fancyapps/fancybox/dist/jquery.fancybox.css';
import "jquery-ui-dist/jquery-ui.min.css";
import '../sass/sass.scss';
import './functions.js';

import Vue from 'vue';

Vue.prototype.$app = app;
Vue.config.productionTip = false;

// main
Vue.component('quiz', require('./components/quiz').default);

new Vue({
	el: '#app',
});