import axios from 'axios';
import http from '../http';

const leadsApi = {
	saveLead(data) {
		return http.post('/leads', data);
	}
}

export {
	leadsApi
}