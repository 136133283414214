import helper from './helpers';
// ui toggle
function enableUIToggle() {
	var toggle = $('.ui-toggle');
	toggle.each(function() {
		var load = $(this).data('toggle');
		if(load == 'show') {
			$(this).find('.ui-toggle-content').css("display", "block");
		}
	});
	$('.ui-toggle-title').on('click', function() {
		$(this).next('.ui-toggle-content').toggle();
	});
}
// checkbox access
function doCheckboxAccess() {
	$('.ui-checkbox input').on('keypress', function(e) {
		e.preventDefault();
		if(e.keyCode === 13) {
			$(this).prop('checked', !$(this).prop('checked'));
		}
	});
}
jQuery(document).ready(function($) {
	
});