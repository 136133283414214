<template>
	<div id="quiz" class="screen" :class="{'secondary': completed_quiz, 'completed-quiz': completed_quiz}">
		<a :href="$app.site_url" class="logo respond">
			<img :src="$app.logo.white" alt="logo">
		</a>
		<div id="quiz-questions" v-show="!completed_quiz">
			<div class="progress clearfix">
				<div class="bar-completion">
					<span class="percentage">0%</span>
				</div>
			</div>
			<div class="step step-1">
				<p class="q-label">How old are you?</p>
				<div class="options">
					<div class="radio" v-for="age, index in list.age">
						<label class="ui-btn ui-btn-rounded" @click="setValueAndProceed('age', age.label, age.info)">
							<input :value="age.label" type="radio" required>
							<span v-text="age.label"></span>
						</label>
					</div>
				</div>
			</div>
			<div class="step step-2">
				<p class="q-label">My smile has...</p>
				<div class="col-row options column-style">
					<div class="col l6 m6 s6" v-for="option, index in list.smile_options">
						<div class="radio">
							<label class="ui-btn ui-btn-rounded" @click="setValueAndProceed('my_smile_has', option.label, option.info)">
								<img :src="$app.assets_url + '/images/icons/' + option.icon + '.png'" :alt="option.label">
								<input :value="option.label" type="radio" required>
								<span v-text="option.label"></span>
							</label>
						</div>
					</div>
				</div>
			</div>
			<div class="step step-3">
				<p class="q-label">How would you rate your current smile?</p>
				<div id="slider-rate">
					<div class="slider-labels"></div>
					<div id="slider"></div>
					<div class="slider-labels-btm">
						<span>Almost <br> perfect!</span>
						<span>Very <br> misaligned</span>
					</div>
				</div>
			</div>
			<div class="step step-4">
				<p class="q-label">Do you have any...</p>
				<div class="options">
					<div class="radio" v-for="option, index in list.do_you_have_any">
						<label class="ui-btn ui-btn-rounded" @click="setValueAndProceed('do_you_have_any', option.label, option.info)">
							<input :value="option.label" type="radio" required>
							<span v-text="option.label"></span>
						</label>
					</div>
				</div>
			</div>
			<div class="invisalign-logo respondmaxi">
				<img :src="$app.assets_url + '/images/logo/logo-invisalign.png'" alt="logo-invisalign">
			</div>
		</div>
		<form id="quiz-form" v-if="completed_quiz" @submit.prevent="saveLead" method="post" action="https://app.dengro.com/register-interest" enctype="multipart/form-data">
			<div class="copy" v-if="!more_info_needed">
				<p class="heading">Congratulations!</p>
				<p>It looks like you're a candidate for Invisalign treatment. <br> <strong>Book your FREE consultation now!</strong></p>
			</div>
			<div class="copy" v-if="more_info_needed">
				<p class="heading">We'd like to assess your smile.</p>
				<p>You may still be a candidate for Invisalign treatment, but we'd like to take a look at your smile to be sure. <br> <strong>Book your FREE consultation now!</strong></p>
			</div>
			<hr>
			<div class="copy">
				<p>Pop your details below, and our in-store team will be in touch to show you how amazing your Smmmile can be!</p>
			</div>
			<input type="hidden" name="token" value="a651553e-b533-3f4a-97b2-46eaadf1f377">
			<input type="hidden" name="treatment" value="invisalign">
			<input type="hidden" name="success_url" :value="$app.site_url + '/thanks'">
			<div class="form-block">
				<label id="your-firstname" class="form-label required">
					<div class="form-field input">
						<input v-model="model.first_name" type="text" name="first_name" placeholder="First name" required>
					</div>
				</label>
			</div>
			<div class="form-block">
				<label id="your-lastname" class="form-label required">
					<div class="form-field input">
						<input v-model="model.last_name" type="text" name="last_name" placeholder="Last name" required>
					</div>
				</label>
			</div>
			<div class="form-block">
				<label id="your-email" class="form-label required">
					<div class="form-field input">
						<input v-model="model.email" type="email" name="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" placeholder="Email address" required>
					</div>
				</label>
			</div>
			<div class="form-block">
				<label id="your-phone-number" class="form-label required">
					<div class="form-field input">
						<input v-model="model.phone_number" type="tel" name="mobile" pattern="[0-9]*" maxlength="20" placeholder="Phone number" required>
					</div>
				</label>
			</div>
			<div class="form-block">
				<label id="your-postcode" class="form-label required">
					<div class="form-field input">
						<input v-model="model.postcode" type="text" name="postcode" maxlength="8" placeholder="Postcode" required>
					</div>
				</label>
			</div>
			<input v-model="model.age" type="hidden" name="meta_data[age]" required>
			<input v-model="model.my_smile_has" type="hidden" name="meta_data[my_smile_has]" required>
			<input v-model="model.smile_rate" type="hidden" name="meta_data[smile_rate]" required>
			<input v-model="model.do_you_have_any" type="hidden" name="meta_data[do_you_have_any]" required>
			<input v-for="param, i in getUtmParams" type="hidden" :name="'meta_data[' + param.key + ']'" :value="param.value">
			<div class="form-block">
				<div id="your-consent" class="form-label required">
					<div class="ui-checkbox">
						<label>
							<input type="checkbox" v-model="model.consent_given" name="consent_given" required>
							<span class="checkbox"></span>
							<span>I agree to Smmmile using my personal data to provide me with dental treatment.</span>
						</label>
					</div>
				</div>
			</div>
			<div class="form-block">
				<div id="your-marketing-consent" class="form-label required">
					<div class="ui-checkbox">
						<label>
							<input type="checkbox" v-model="model.marketing_consent_given" name="marketing_consent_given">
							<span class="checkbox"></span>
			 				<span>I agree to Smmmile using my personal data to keep me informed about marketing offers and initiatives that may be of interest.</span>
						</label>
					</div>
				</div>
			</div>
			<button type="submit" class="submit ui-btn" :disabled="isSavingLead || !model.consent_given" v-text="isSavingLead ? 'Processing...' : 'Submit'">Submit</button>
		</form>
	</div>
</template>
<script>
	import {slider} from 'jquery-ui';
	import helper from '../../helpers';
	import {leadsApi} from '../../api';
	import {includes} from 'lodash';
	export default {
		data() {
			return {
				more_info_needed: false,
				isSavingLead: false,
				completed_quiz: false,
				params: {},
				model: {},
				step: 1,
				total_steps: 0,
				smile_rating_options: [
					"congrats",
					"congrats",
					"congrats",
					"more-info",
					"more-info",
				],
				list: {
					age: [
						{label: "Under 18", info: "congrats"},
						{label: "19-39", info: "congrats"},
						{label: "40-59", info: "congrats"},
						{label: "60+", info: "more-info"},
					],
					smile_options: [
						{label: "Gapped Teeth", info: "congrats", icon: "Wireframe-_Gapped teeth"},
						{label: "Crowded Teeth", info: "congrats", icon: "Wireframe-_Overcrowded"},
						{label: "Underbite", info: "more-info", icon: "Wireframe-_Underbite"},
						{label: "Open Bite", info: "more-info", icon: "Wireframe-_Open Bite"},
						{label: "Crossbite", info: "more-info", icon: "Wireframe-_Cross bite"},
						{label: "Deep Bite", info: "more-info", icon: "Wireframe-_Deepbite"},
					],
					do_you_have_any: [
						{label: "Implants", info: "more-info"},
						{label: "Bridges", info: "more-info"},
						{label: "Missing teeth", info: "more-info"},
						{label: "None of these", info: "congrats"},
					],
				}
			}
		},
		methods: {
			saveLead() {
				let self = this;
				self.isSavingLead = true;
				if(self.model.consent_given) {
					leadsApi.saveLead(self.model).then(res => {
						// submit form
						$('#quiz-form')[0].submit();
					}, error => {
						self.isSavingLead = false;
						var alertmsg = "Something went wrong";
						if(error.data.hasOwnProperty('code')) {
							switch(error.data.code) {
								case 'validation_errors': 
									alertmsg = "Please correct form errors";
									break;
								case 'lead_failed': 
									alertmsg = error.data.message;
									break;
							}
						}
						alert(alertmsg);
						console.log(error);
					});
				}
			},
			setValue(key, value, info) {
				let self = this;
				self.model[key] = value;
				if(info === "more-info") {
					self.more_info_needed = true;
				}
			},
			setValueAndProceed(key, value, info) {
				let self = this;
				self.setValue(key, value, info);
				self.changeStep('next');
			},
			validateForm() {
				let self = this, model = self.model;
				var isValid = false;
				switch(self.step) {
					case 1:
						if(model.age) {
							isValid = true;
						}
						break;
					case 2:
						if(model.my_smile_has) {
							isValid = true;
						}
						break;
					case 3:
						if(model.smile_rate) {
							isValid = true;
						}
						break;
					case 4:
						if(model.do_you_have_any) {
							isValid = true;
						}
						break;
					default:
						break;
				}
				return isValid;
			},
			changeStep(direction) {
				let self = this, proceed = true;
				proceed = self.validateForm();
				if(direction === 'next') {
					proceed = self.validateForm();
				}
				if(proceed) {
					if(self.isLastStep) {
						self.completed_quiz = true;
					}
					if(self.step >= 1 && self.step <= self.total_steps) {
						if(direction === 'next') {
							self.step += 1;
						}
						if(direction === 'prev') {
							self.step -= 1;
						}
					}
					self.goToStep(self.step - 1);
					self.calcProgress();
				}
			},
			goToStep(step) {
				$('.step').hide().eq(step).show();
			},
			calcProgress() {
				let self = this;
				var total_steps = self.total_steps,
					percentage = ((self.step) * 100 / (total_steps + 1)).toFixed(0);
				$('.percentage').text(percentage + '%');
				$('.bar-completion').width(percentage + '%');
			},
			initModel() {
				let self = this;
				self.model = {
					first_name: "",
					last_name: "",
					email: "",
					phone_number: "",
					postcode: "",
					age: "",
					my_smile_has: "",
					smile_rate: 0,
					do_you_have_any: "",
					consent_given: false,
					marketing_consent_given: false,
				}
			},
			initMounted() {
				let self = this;
				self.goToStep(self.step - 1);
				self.total_steps = $('#quiz .step').length;
				self.calcProgress();
				self.initModel();
				var $slider = $("#slider").slider({
					orientation: "horizontal",
					value: 3,
					min: 1,
					max: self.smile_rating_options.length,
					step: 1,
					slide: function(event, ui) {
						$('.slider-labels').find('span').removeClass('active').eq(ui.value - 1).addClass('active');
					},
					stop: function(event, ui) {
						setTimeout(() => {
							self.setValueAndProceed('smile_rate', ui.value, self.smile_rating_options[ui.value - 1]);
						}, 500);
					}
				});
				$slider.each(function() {
					var opt = $(this).data().uiSlider.options;
					var vals = opt.max - opt.min;
					for (var i = 0; i <= vals; i++) {
						var el = $('<span>' + (i + 1) + '</span>');
						$('#slider-rate').find('.slider-labels').append(el);
					}
				});
				self.params = helper.getUrlParams();
			}
		},
		computed: {
			isFirstStep() {
				let self = this;
				return self.step === 1;
			},
			isLastStep() {
				let self = this;
				return self.step === self.total_steps;
			},
			getUtmParams() {
				let self = this, utms = [];
				if(self.params) {
					Object.entries(self.params).forEach(([key, value]) => {
						if(key.includes('utm_')) {
							utms.push({
								key: key,
								value: value
							});
						}
					});
				}
				return utms;
			}
		},
		mounted() {
			let self = this;
			self.initMounted();
		}
	}
</script>